import React from "react"
import styled from "styled-components"
import { flexbox, layout } from "styled-system"

const FullColumn = styled.div`
  ${layout}
  ${flexbox}
`

export default props => {
  const { children } = props
  return (
    <FullColumn flexBasis={"100%"} {...props}>
      {children}
    </FullColumn>
  )
}
