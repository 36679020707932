import React, { Fragment } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import ReactPlayer from "react-player"

// Import Global Components
import BlockContent from "@sanity/block-content-to-react"
import LeftColumn from "~components/leftColumn"
import RightColumn from "~components/rightColumn"
import FullColumn from "~components/fullColumn"
import Gif from "~components/gif"
import Video from "~components/video"

// Import Local Asset(s)
import SymbolSrc from "./assets/images/symbol.png"

const Symbol = styled.img`
  margin: 4rem 0;
`

const ProjectInformation = styled(LeftColumn)`
  box-sizing: border-box;
  padding: 0.75rem;
  padding-right: 1.5rem;
`

ProjectInformation.Title = styled.h1`
  margin-bottom: 1rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.background};
  font-family: ${({ theme }) => theme.fonts.moonbase};
  font-size: 2.5rem;
  font-weight: normal;
`

ProjectInformation.SubTitle = styled.p`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fonts.optima};
  font-weight: bold;
`

ProjectInformation.Role = styled.p`
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.fonts.optima};
  font-weight: bold;
`

ProjectInformation.Credits = styled.ul`
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  column-count: 2;
  border-bottom: 2px solid ${({ theme }) => theme.colors.background};
`

const ProjectHero = styled(RightColumn)`
  display: ${({ mobile }) => (mobile ? "none" : "block")};
  padding: 0.75rem;
  padding-left: 0;
  @media (max-width: 900px) {
    display: ${({ desktop }) => (desktop ? "none" : "block")};
    margin-top: 0;
    padding-left: 0.75rem;
  }
`
const StyledFullColumn = styled(FullColumn)`
  margin-top: 1.5rem;
  @media (max-width: 900px) {
    padding: 0.75rem;
    margin-bottom: 3rem;
  }
`
const ProjectSummary = styled.div`
  margin-top: 1rem;
  & p {
    margin-bottom: 1rem;
  }
`
const ProjectImages = styled.div`
  margin-top: 1.5rem;
`
const ProjectImage = styled(Img)`
  margin-top: 1.5rem;
`
const ResponsivePlayerWrapper = styled.div`
  background-color: #000000;
  margin: 0 auto;
  margin-top: 1.5rem;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  > .react-player {
    width: 100%;
  }
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
`
const TwoColumnLayout = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`

const LeftColumnLayout = styled.div`
  box-sizing: border-box;
  flex-basis: 50%;
  padding-right: 0.75rem;
`
const RightColumnLayout = styled.div`
  box-sizing: border-box;
  flex-basis: 50%;
  padding-left: 0.75rem;
`
export default ({
  data: {
    sanityProject: {
      title,
      subTitle,
      role,
      _rawSummary,
      credits,
      hero,
      assets,
    },
  },
}) => {
  return (
    <Fragment>
      <ProjectHero mobile>
        <Img
          fluid={hero.asset.fluid}
          alt={hero.caption}
          placeholderStyle={{ filter: "blur(20px)" }}
        />
      </ProjectHero>
      <ProjectInformation>
        <ProjectInformation.Title>{title}</ProjectInformation.Title>
        <ProjectInformation.SubTitle>{subTitle}</ProjectInformation.SubTitle>
        <ProjectInformation.Role>{role}</ProjectInformation.Role>
        <ProjectInformation.Credits>
          {credits.map(({ _key, role, credited }) => {
            return (
              <li key={_key}>
                <b>{`${role}:`}</b> {credited.map(credit => `${credit}`)}
              </li>
            )
          })}
        </ProjectInformation.Credits>
        <ProjectSummary>
          <BlockContent blocks={_rawSummary} />
        </ProjectSummary>
      </ProjectInformation>
      <ProjectHero desktop>
        <Img
          fluid={hero.asset.fluid}
          alt={hero.caption}
          placeholderStyle={{ filter: "blur(20px)" }}
        />
      </ProjectHero>
      <StyledFullColumn>
        <ProjectImages>
          {assets.map(asset => {
            if (asset._type === "imageWithCaption") {
              const { asset: imageWithCaption } = asset
              if (imageWithCaption.extension === "gif") {
                return (
                  <Gif
                    key={asset._key}
                    placeholder={imageWithCaption.fluid.base64}
                    src={imageWithCaption.url}
                  />
                )
              } else {
                return (
                  <ProjectImage
                    fluid={imageWithCaption.fluid}
                    key={asset._key}
                    placeholderStyle={{ filter: "blur(20px)" }}
                  />
                )
              }
            }
            if (asset._type === "videoUrl") {
              const { videoUrl } = asset
              return (
                <ResponsivePlayerWrapper key={asset._key}>
                  <StyledReactPlayer
                    width="100%"
                    height="100%"
                    className="react-player"
                    url={videoUrl}
                    controls
                  />
                </ResponsivePlayerWrapper>
              )
            }
            if (asset._type === "twoColumnLayout") {
              let leftFlexBasis = null
              let rightFlexBasis = null
              if (
                asset.leftColumnAsset[0].asset &&
                asset.rightColumnAsset[0].asset
              ) {
                const leftRatio =
                  asset.leftColumnAsset[0].asset.fluid.aspectRatio
                const rightRatio =
                  asset.rightColumnAsset[0].asset.fluid.aspectRatio
                const totalRatio = leftRatio + rightRatio
                leftFlexBasis = (leftRatio / totalRatio) * 100
                rightFlexBasis = (rightRatio / totalRatio) * 100
              }
              return (
                <TwoColumnLayout key={asset._key}>
                  <LeftColumnLayout
                    style={{ flexBasis: leftFlexBasis + "%" || "50%" }}
                  >
                    {asset.leftColumnAsset[0]._type === "imageWithCaption" ? (
                      asset.leftColumnAsset[0].asset.extension === "gif" ? (
                        <Gif
                          key={asset._key}
                          placeholder={
                            asset.leftColumnAsset[0].asset.fluid.base64
                          }
                          src={asset.leftColumnAsset[0].asset.url}
                        />
                      ) : (
                        <Img
                          fluid={asset.leftColumnAsset[0].asset.fluid}
                          key={asset._key}
                          placeholderStyle={{ filter: "blur(20px)" }}
                        />
                      )
                    ) : (
                      <ResponsivePlayerWrapper key={asset._key}>
                        <StyledReactPlayer
                          width="100%"
                          height="100%"
                          url={asset.leftColumnAsset[0].videoUrl}
                          controls
                        />
                      </ResponsivePlayerWrapper>
                    )}
                  </LeftColumnLayout>
                  <RightColumnLayout
                    style={{ flexBasis: rightFlexBasis + "%" || "50%" }}
                  >
                    {asset.rightColumnAsset[0]._type === "imageWithCaption" ? (
                      asset.rightColumnAsset[0].asset.extension === "gif" ? (
                        <Gif
                          placeholder={
                            asset.rightColumnAsset[0].asset.fluid.base64
                          }
                          src={asset.rightColumnAsset[0].asset.url}
                        />
                      ) : (
                        <Img
                          fluid={asset.rightColumnAsset[0].asset.fluid}
                          key={asset._key}
                          placeholderStyle={{ filter: "blur(20px)" }}
                        />
                      )
                    ) : (
                      <ResponsivePlayerWrapper key={asset._key}>
                        <StyledReactPlayer
                          width="100%"
                          height="100%"
                          url={asset.rightColumnAsset[0].videoUrl}
                          controls
                        />
                      </ResponsivePlayerWrapper>
                    )}
                  </RightColumnLayout>
                </TwoColumnLayout>
              )
            }
            if (asset._type === "video" && asset.asset) {
              return <Video src={asset.asset.url} />
            }
          })}
        </ProjectImages>
        <Symbol src={SymbolSrc} alt={"Shape"} />
      </StyledFullColumn>
    </Fragment>
  )
}

export const query = graphql`
  query PostQuery($id: String) {
    sanityProject(id: { eq: $id }) {
      title
      subTitle
      city
      role
      _rawSummary
      tags {
        _key
        name
      }
      assets {
        ... on SanityImageWithCaption {
          _key
          _type
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
            url
            extension
          }
        }
        ... on SanityVideoUrl {
          _key
          _type
          videoUrl
        }
        ... on SanityVideo {
          _key
          _type
          asset {
            url
          }
        }
        ... on SanityTwoColumnLayout {
          _key
          _type
          leftColumnAsset {
            ... on SanityImageWithCaption {
              _key
              _type
              asset {
                _type
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
                url
                extension
              }
            }
            ... on SanityVideoUrl {
              _key
              _type
              videoUrl
            }
          }
          rightColumnAsset {
            ... on SanityImageWithCaption {
              _key
              _type
              asset {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
                url
                extension
              }
            }
            ... on SanityVideoUrl {
              _key
              _type
              videoUrl
            }
          }
        }
      }
      credits {
        credited
        role
        _key
      }
      hero {
        asset {
          id
          url
          fluid {
            ...GatsbySanityImageFluid
          }
          extension
        }
        caption
      }
    }
  }
`
