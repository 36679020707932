import React, { useState } from "react"
import styled from "styled-components"

const GifContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  margin-top: 1.5rem;
  overflow: hidden;
`

const Placeholder = styled.img`
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  width: 100%;
  filter: blur(20px);
  transition: opacity 0.6s;
`

const Gif = styled.img`
  opacity: ${({ loaded }) => (loaded ? 0 : 1)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.3s;
`

export default ({ placeholder, src }) => {
  const [loaded, setIsLoaded] = useState(false)
  return (
    <GifContainer>
      <Placeholder src={placeholder} loaded={loaded} />
      <Gif
        src={src}
        loaded={loaded}
        onload={() => {
          setIsLoaded(true)
        }}
      />
    </GifContainer>
  )
}
