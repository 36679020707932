import React from "react"
import styled from "styled-components"
import { flexbox, layout } from "styled-system"

const RightColumn = styled.div`
  ${layout}
  ${flexbox}
  box-sizing: border-box;
`

export default props => {
  const { children } = props
  return (
    <RightColumn
      width={[1, 0.5]}
      flexBasis={["100%", "50%"]}
      order={[-1, 0]}
      {...props}
    >
      {children}
    </RightColumn>
  )
}
