import React from "react"
import styled from "styled-components"
import { Player } from "video-react"

import "video-react/dist/video-react.css"

const StyledPlayer = styled(Player)`
  margin-top: 1.5rem;
`

export default ({ src }) => {
  return <StyledPlayer playsInline src={src} />
}
